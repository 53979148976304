import React, { useEffect, useState } from 'react'

function CharacterSkills({
  character,
  handleDiceRoll,
  editMode,
  handleSaveCharacter,
}) {
  const [skills, setSkills] = useState([...character.skills])

  useEffect(() => {
    if (!editMode) {
      setSkills([...character.skills])
    }
  }, [editMode, character])

  const refreshListSkills = () => {
    const updatedSkills = [...skills]
    character.skills.forEach((s) => {
      const index = skills.findIndex((skill) => skill.name === s.name)
      if (index === -1) {
        updatedSkills.push(s)
      }
    })
    setSkills(
      updatedSkills.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    )
  }

  const handleUpdateSkill = (index, skill) => {
    const updatedSkills = [...skills]
    updatedSkills[index] = skill
    setSkills(
      updatedSkills.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    )
  }

  return (
    <div className="character-sheetpage_skills">
      {skills.map((s, index) => (
        <div
          key={`skills_${index}`}
          className="character-sheetpage_skills_stat character-hover"
          onClick={() => (editMode ? null : handleDiceRoll(s.name, s.value))}
        >
          <div className="sheetpage_skills_stat_name">
            {editMode ? (
              <input
                type="text"
                value={s.name}
                onChange={(e) =>
                  handleUpdateSkill(index, { ...s, name: e.target.value })
                }
                onBlur={() => refreshListSkills()}
              />
            ) : (
              <span>{s.name}</span>
            )}
          </div>
          <div className="sheetpage_skills_stat_value">
            {editMode ? (
              <input
                type="text"
                value={s.value}
                onChange={(e) =>
                  handleUpdateSkill(index, { ...s, value: e.target.value })
                }
              />
            ) : (
              <span>{s.value}</span>
            )}
          </div>
          {editMode && (
            <div className="sheetpage_skills_stat_delete">
              <button
                onClick={() => {
                  if (window.confirm('Sei sicuro di voler cancellare la skill?')) {
                    const updatedSkills = [...skills]
                    updatedSkills.splice(index, 1)
                    setSkills(updatedSkills)
                  }
                }}
              >
                X
              </button>
            </div>
          )}
        </div>
      ))}
      {editMode && (
        <button onClick={() => handleSaveCharacter({ ...character, skills })}>
          Save
        </button>
      )}
    </div>
  )
}

export default CharacterSkills
